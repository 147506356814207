export const Potugues = {
  "Flash Technologies L.L.C-FZ": "Flash Technologies L.L.C-FZ",
  "Audited by": "Auditado por",
  Flash: "Flash",
  Technologies: "Technologies",
  "Buy Flash": "Comprar Flash",
  "Flash Technologies creates a money transfer ecosystem.":
    "A Flash Technologies cria um ecossistema de transferência de dinheiro.",
  "Flash Transfer is the world's first platform that allows you to buy crypto with cash, transfer, or sell cryptocurrencies for cash through our network of merchants, with reasonable fees. Flash Wallet, our non-custodial wallet, has a smart contract that you can use to transfer your crypto in the event of death.  Find the best fees with Flash Dex, our aggregator swap & bridge!":
    "Flash Transfer é a primeira plataforma do mundo que permite comprar criptomoedas com dinheiro, transferir ou vender criptomoedas por dinheiro através da nossa rede de comerciantes, com taxas razoáveis. Flash Wallet, nossa carteira não custodial, possui um contrato inteligente que você pode usar para transferir suas criptomoedas em caso de falecimento. Encontre as melhores taxas com Flash Dex, nosso agregador de swap e bridge!",
  CONTACT: "Contacto",
  "Contact Us": "Contacte-nos",
  "Whether you have a question about one of our services or want to get involved in our mission, we are ready to help.":
    "Quer tenha uma pergunta sobre um dos nossos serviços ou queira envolver-se na nossa missão, estamos prontos a ajudar.",
  "Follow us:": "Siga-nos:",
  "First Name": "Primeiro nome",
  "Last Name": "Sobrenome",
  "Your Email Address": "O seu endereço de correio electrónico",
  "Tell us about your project": "Fale-nos do seu projecto",
  "Send Message": "Enviar mensagem",
  "Type Here": "Escreva aqui",
  Our: "A nossa",
  "CORE TEAM": "EQUIPA DE CORE",
  "has worked with": "trabalhou com",
  "Core Team": "Equipa Central",
  "Aladdin CEO & Founder": "Aladdin CEO & Founder",
  "CEO & Founder of": "CEO & Fundador de",
  Blocklabchain: "Blocklabchain",
  Hulk: "Hulk Cars",
  "Safi CCO & Co-Founder": "Safi CCO & Co-Founder",
  "Les Artisans d’Orient": "Les Artisans d’Orient",
  "Junior COO": "Junior COO",
  "Communication English & German. Works in":
    "Comunicação Inglês & Alemão. Trabalhos em",
  "Diplomatic Institution": "Instituição Diplomática",
  "Binance feed creator": "Criador de rações de Binance",
  "Mimirk CPRO": "Mimirk CPRO",
  "Chief Public Relations Officer. Works in": "Director de marketing. Trabalha como",
  "Public Relations":
    "especialista em marketing e relações públicas",
  "Dev. Team": "Dev. Equipa",
  "Ernest CTO": "Ernest CTO",
  "Frontend Developer": "Front-end Desenvolvedor",
  "Oleksii Developer": "Oleksii Desenvolvedor",
  "Full stack & Blockchain Developer": "Revelador de pilha cheia & Blockchain",
  "Jawad Developer": "Jawad Developer",
  "Community Manager": "Gerente de comunidade",
  "Full stack Developer": "Revelador de pilha completa",
  "Ahsan Developer": "Ahsan Developer",
  "Umair Developer": "Umair Developer",
  "Usama Developer": "Desenvolvedor de Usama",
  "Vadim Developer": "Desenvolvedor Vadim",
  "Blockchain Developer": "Revelador de correntes de bloqueio",
  "Alex Developer": "Desenvolvedor Alex",
  "Frontend & Blockchain Developer": "Desenvolvedor de Frontend & Blockchain",
  "Hassam Editor video": "Vídeo do editor Hassam",
  "Video creation and editor": "Criação e edição de vídeo",
  "Runnart Designer": "Runnart Designer",
  "Administrative graphic": "Gráfico administrativo",
  "White paper": "White paper",
  "& App logo designer": "& App logo designer",
  "Eznau Illustrator": "Ilustrador Eznau",
  "Graphic flyers, logos": "Folhetos gráficos, logótipos",
  "NFT for all products of": "NFT para todos os produtos da",
  "Flash Technologies": "Flash Technologies",
  "Numa Designer UI/UX": "Numa Designer UI/UX",
  "Flash Transfer": "Flash Transfer",
  "Flash Wallet App": "Flash Wallet App",
  "UI/UX designer": "UI/UX designer",
  "for": '',
  "Products": 'Produtos',
  "ITO Designer": "ITO Designer",
  FAQ: "FAQ",
  "What is Flash technologies?": "O que são as Flash Technologies ?",
  "Flash Technologies is the name that represents all Flash projects.":
    "Flash Technologies é o nome que representa todos os projectos Flash.",
  "How can I buy Flash?": "Como posso comprar Flash?",
  "You can buy Flash token directly on":
    "Pode comprar as nossas fichas directamente em",
  "What is Flash Transfer?": "O que é a Transferência Flash?",
  "Flash Transfer is the main project of Flash Technologies. We will be able to bank people who do not have a bank account or who wish to free themselves from traditional banks. We can exchange cash for crypto via our various partners. Users of this service will be able to make transfers worldwide.":
    "Flash Transfer é o projecto principal da Flash Technologies. Poderemos bancar pessoas que não tenham conta bancária ou que desejem libertar-se dos bancos tradicionais. Podemos trocar dinheiro por criptográfico através dos nossos vários parceiros. Os utilizadores deste serviço poderão fazer transferências em todo o mundo.",
  "What are the advantages of buying Flash ?":
    "Quais são as vantagens de comprar Flash ?",
  "Your Flash increases in value according to the evolution of the course. When Flash Technologies projects evolve, it has a positive impact on the price.":
    "O seu Flash aumenta de valor de acordo com a evolução do curso. Quando os projectos da Flash Technologies evoluem, tem um impacto positivo no preço.",
  "On which site will the token be listed?":
    "Em que sítio será listado o símbolo?",
  "The token will be listed on cex and dex.":
    "A ficha será listada em cex e dex.",
  "Why transaction fees?": "Porquê taxas de transacção?",
  "Transaction fees support the project.":
    "As taxas de transacção apoiam o projecto.",
  "Whitepaper EN": "Whitepaper PT",
  Presentation: "Apresentação",
  "Using Flash Wallet": "Usando a Carteira Flash",
  SERVICES: "SERVIÇOS",
  "(replace busd with ETH)": "",
  "Flash Ecosystem": "Ecossistema Flash",
  "Discover all our services.": "Descubra todos os nossos serviços.",
  "Exchange your cash vs crypto or crypto vs cash. Buy directly cryptocurrency with our approved partners. You can also send your cryptocurrency and the receiver withdraws it in fiat all over the world using a Binance account or using your non custodial wallet ( Flash wallet, Metamask, Trust wallet ...).":
    "Troque o seu dinheiro vs criptográfico ou criptográfico vs dinheiro. Compre directamente a moeda criptográfica com os nossos parceiros aprovados. Também pode enviar a sua moeda criptográfica e o destinatário retira-a em fiat em todo o mundo utilizando uma conta Binance ou utilizando a sua carteira sem custódia ( Flash wallet, Metamask, Trust wallet ...).",
  "Flash Dex": "Dex Flash",
  "Flash dex dapps is an all-in-one swap and bridge that allows users to swap their crypto assets from the same blockchain or from one blockchain to another. With the best possible direct market fees.":
    "O Flash dex dapps é uma ponte e uma troca tudo-em-um que permite aos utilizadores trocar os seus activos criptográficos da mesma cadeia de blocos ou de uma cadeia de blocos para outra. Com as melhores taxas de mercado directas possíveis.",
  "Flash Technologies has built a fully integrated cryptocurrency wallet that holders and merchants can use to buy, sell, stake, earn rewards and browse on internet. Flash Wallet has a special function that is unique in cryptospace. A very important feature that users will enjoy everywhere in the world.":
    "A Flash Technologies construiu uma carteira de moeda criptográfica totalmente integrada que os titulares e comerciantes podem utilizar para comprar, vender, apostar, ganhar prémios e navegar na Internet. A Carteira Flash tem uma função especial que é única no espaço criptográfico. Uma característica muito importante que os utilizadores irão desfrutar em qualquer parte do mundo.",
  "Flash Wallet": "Carteira Flash",
  "Dead Wallet": "Flash Dead",
  "The 'dead-wallet' function. This function enables the recovery of cryptos in another wallet in case of loss of seed phrase and in the event of a death of a crypto holder. It is embedded with a smart-contract linked to a timer that automatically triggers the transfer of funds out of the wallet after a period of time has elapsed. With extra security if ever the dead wallet doesn't have enough gas costs.":
    "A função 'dead-wallet'. Esta função permite a recuperação de criptomoedas em outra carteira em caso de perda da frase de recuperação ou em caso de falecimento do titular da criptomoeda. A carteira está equipada com um contrato inteligente vinculado a um cronômetro que aciona a transferência de fundos para fora da carteira após um período de tempo; o destinatário deve reivindicar as criptomoedas. Para usar esse recurso, visite o aplicativo flash-wallet.com.",
  "Flash Pay": "Flash Pay",
  "FlashPay, escrow agreements can be created quickly by buyer or seller. Once receipt of goods or services is confirmed funds are immediately transferred to the seller via the smart contract. In the event of a dispute, Flash Pay settles the dispute with the evidence received. At no time are the funds held by Flash Pay, everything is decentralized via the smart contract. Flash Pay can only validate or refuse the payment, nothing else.":
    "FlashPay, os contratos de caução podem ser criados rapidamente pelo comprador ou vendedor. Uma vez confirmado o recebimento de bens ou serviços, os fundos são imediatamente transferidos para o vendedor por meio do contrato inteligente. Em caso de disputa, o Flash Pay resolve a disputa com as evidências recebidas. Em nenhum momento os recursos são retidos pelo Flash Pay, tudo é descentralizado por meio do contrato inteligente. O Flash Pay só pode validar ou recusar o pagamento, nada mais.",
  "Customers and merchants have the opportunity to earn rewards by using the staking protocol. Each month stakers receive a % of our staking pool.":
    "Os clientes e comerciantes têm a oportunidade de ganhar prémios utilizando o protocolo de aposta. Todos os meses os stakers recebem uma % do nosso staking pool.",
  "Flash Staking": "Estaqueamento Flash",
  "Flash audit will audit smart contracts (tokens, bridges, staking, all types of dapps) and blockchains of our partners/customers on all networks.":
    "A auditoria flash auditará contratos inteligentes (fichas, pontes, estacas, todos os tipos de dapps) e cadeias de bloqueio dos nossos parceiros/clientes em todas as redes. Cada parceiro/cliente que deseje estar no nosso ecossistema terá de passar pela nossa auditoria de segurança para ter a certeza de que não há código malicioso.",
  "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.":
    "Cada parceiro/cliente que deseje estar no nosso ecossistema terá de passar pela nossa auditoria de segurança para ter a certeza de que não há código malicioso.",
  "Flash Audit": "Auditoria Flash",
  "Flash Launchpad": "Flash Launchpad",
  "Flash pad is our launchpad for upcoming IDOs and allows crypto projects to create their tokens (Ethereum, BNB Chain, Polygon, Base, Avax, Cronos, Arbitrum, Blast) and raise funds in pre-sales or fair launches.You can also use the airdrop feature and the audit bot to quickly audit tokens.":
    "Flash pad é a nossa plataforma de lançamento para os próximos IDOs e permite aos projectos de criptografia criar os seus tokens (BNB Chain, Polygon, Eth & Cronos) e angariar fundos em pré-vendas ou lançamentos em feiras.",
  "Secured blockchain": "Cadeia de bloqueio segura",
  "Single transaction": "Transacção única",
  "Deflationary token": "Ficha deflacionária",
  "Replace a Bank Account": "Substituir uma conta bancária",
  "WE ARE SUPPORTED": "SOMOS APOIADOS",
  "Flash Recharge Card": "Cartão de Recarga Flash",
  "Flash Recharge": "Recarga Flash",
  "The Flash recharge is a prepaid card that allows users to top up their account in $ BUSD on the Flash wallet without any intermediary.":
    "A recarga Flash é um cartão pré-pago que permite aos utilizadores recarregar a sua conta em $ BUSD na carteira Flash sem qualquer intermediário.",
  "Flash Recharge allows users to top up their balance on Flash Wallet.":
    "O Flash Recharge permite aos utilizadores recarregar o seu saldo na Carteira Flash.",
  "The Flash recharge's cards will be printed at the time of purchase for an eco-responsible approach.":
    "Os cartões da recarga Flash serão impressos no momento da compra para uma abordagem eco-responsável.",
  "Cash to crypto and vice versa.": "Dinheiro para criptografar e vice-versa.",
  "DOWNLOAD FROM": "DOWNLOAD DE",
  "Our Services": "Os Nossos Serviços",
  "App": "App",
  Tokenomics: "Tokenomics",
  Partnership: "Parceria",
  Roadmap: "Roteiro",
  NFT: "NFT",
  Team: "Equipa",
  Whitepaper: "Whitepaper",
  Audit: "Auditoria",
  "Become a Partner": "Torne-se um parceiro",
  'Presale': "Presale",
  "Privacy Policy": "Política de Privacidade",
  "Legal Notice": "Aviso legal",
  License: "Licença",
  "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.":
    "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.",
  "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.":
    "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.",
  "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.":
    "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.",
  Pages: "Páginas",
  Documents: "Documentos",
  Legal: "Legal",
  "Join Our Newsletter": "Junte-se ao nosso boletim informativo",
  "Your email address": "O seu endereço de correio electrónico",
  Subscribe: "Subscrever",
  "Will send you weekly updates for your better finance management.":
    "Enviar-lhe-á actualizações semanais para a sua melhor gestão financeira.",
  Partners: "Parceiros",
  Contact: "Contacto",
  Staking: "Empilhamento",
  "Invest in your Future": "Invista no seu futuro",
  "Clients can stake Flash or our approved partner's tokens for a certain period of time in order to earn rewards and bonuses":
    "Os clientes podem apostar no Flash ou nas fichas do nosso parceiro aprovado durante um certo período de tempo para ganhar recompensas e bónus",
  Stake: "Estaca",
  "Stake FLASH and receive rewards.": "Estacar FLASH e receber recompensas.",
  "Complete Staking Term": "Termo de Estaqueamento Completo",
  "Users can choose the staking terms and hold their stake for s period of time to earn higher yield bonus returns.":
    "Os utilizadores podem escolher as condições de aposta e manter a sua aposta por um período de tempo para ganharem rendimentos mais elevados de bónus.",
  "Pay via Flash and partner tokens": "Pagar via Flash e fichas de parceiros",
  "After every successful stake, users are rewarded with Flash token as a farming bonus and partners tokens.":
    "Após cada aposta bem sucedida, os utilizadores são recompensados com um token Flash como bónus agrícola e tokens de parceiros.",
  "Listing & Partners": "Listagem & Parceiros",
  "Seen in the media": "Visto nos meios de comunicação social",
  "GET YOUR NFT UTILITY": "ADQUIRA A SUA UTILIDADE NFT",
  Airdrop: "Airdrop",
  "Each month receive Flash in your wallet.":
    "Todos os meses recebe Flash na sua carteira.",
  "Holders of the AIRDROP NFTS receive Flash tokens every month. The amount differs depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed in the future.":
    "Os detentores do AIRDROP NFTS recebem todos os meses fichas Flash. O montante difere em função da raridade do NFT. Também dá acesso a outros benefícios, incluindo o clube vip (call nft & tokens), lista branca, descontos e outros benefícios que serão divulgados no futuro.",
  "Ranking Increase": "Aumento do Ranking",
  "Will quickly increase the privilege ranking.":
    "Irá aumentar rapidamente a classificação de privilégios.",
  "The NFTS RANKING INCREASE allow you to increase quickly your ranking points on Flash Transfer Website. The earned points depend on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "O NFTS RANKING INCREASE permite-lhe aumentar rapidamente os seus pontos de classificação no sítio Web de Transferência Flash. Os pontos ganhos dependem da raridade do NFT. Também dá acesso a outros benefícios, incluindo o clube vip (call nft & tokens), lista branca, descontos e outros benefícios que serão divulgados mais tarde.",
  "Discount on transaction fees": "Desconto nas taxas de transacção",
  "Will entitle you to fees on money transfer site transactions.":
    "Dará direito a taxas sobre transacções no local de transferência de dinheiro.",
  "The NFTS DISCOUNT ON TRANSACTION FEES provide discounts on transaction fees. The reduction is bigger depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "A NFTS DISCOUNT SOBRE TAXAS DE TRANSACÇÃO oferece descontos nas taxas de transacção. A redução é maior, dependendo da raridade da NFT. Também dá acesso a outros benefícios, incluindo o clube vip (call nft & tokens), lista branca, descontos e outros benefícios que serão divulgados mais tarde.",
  "Random effect": "Efeito aleatório",
  "Every 3 months or 6 months the function of the NFT changes.":
    "A cada 3 meses ou 6 meses a função do NFT muda.",
  "The NFTS RANDOM EFFECT change abilities every 4 months. The benefit is greater depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "O NFTS RANDOM EFFECT muda de capacidade a cada 4 meses. O benefício é maior, dependendo da raridade da NFT. Também dá acesso a outros benefícios, incluindo o clube vip (call nft & tokens), lista branca, descontos e outros benefícios que serão divulgados mais tarde.",
  "Flash Technologies Roadmap": "Roteiro das Flash Technologies",
  "August 2021": "Agosto 2021",
  "Market study and meeting with professionals for the feasibility of the project.":
    "Estudo de mercado e reunião com profissionais para a viabilidade do projecto.",
  "September 2021": "Setembro 2021",
  "Definition of the project and creation of the whitepaper.":
    "Definição do projecto e criação do whitepaper.",
  "October 2021": "October 2021",
  "Creation of the team of developers. Creation of the Flash Token Website.":
    "Criação da equipa de programadores. Criação do website do Flash Token.",
  "November 2021": "Novembro 2021",
  "Implementation of the Marketing strategy. Creation of the money transfer platform website. Meeting with influencers for the promotion of Flash Token. Launch of the Flash Token on the Bsc.":
    "Implementação da estratégia de Marketing. Criação do website da plataforma de transferência de dinheiro. Reunião com os influenciadores para a promoção do Flash Token. Lançamento do Flash Token na Bsc.",
  "December 2021": "Dezembro 2021",
  "Promotion of the Flash Token by influencers.":
    "Promoção do Flash Token por influenciadores.",
  "January 2022": "Janeiro 2022",
  "Launch of the 1st NFT collection. Launch of the Flash Token on the Bsc.":
    "Lançamento da 1ª colecção NFT. Lançamento do Token Flash na Bsc.",
  "February 2022": "Fevereiro 2022",
  "Release of the beta version of the money transfer. Coming out of the Nft mint platform Deployment.":
    "Lançamento da versão beta da transferência de dinheiro. Saída da plataforma Nft mint Deployment.",
  2022: "2022",
  "Launch of Flash Technologies website":
    "Lançamento do website da Flash Technologies",
  "Unveiling of the first Flash Transfer partners":
    "Revelação dos primeiros parceiros de Transferência Flash",
  "Launch of Flash Audit": "Lançamento da Auditoria Flash",
  "Vip Club Launch (only for the 200 Nft)":
    "Lançamento do Clube Vip (apenas para os 200 Nft)",
  "Launch of the advertising campaign with influencers + new ambassadors":
    "Lançamento da campanha publicitária com influenciadores + novos embaixadores",
  "Development of the Audit platform":
    "Desenvolvimento da plataforma de Auditoria",
  "Development of the Staking platform":
    "Desenvolvimento da plataforma de piquetagem",
  "Beta Launch Flash Transfer": "Transferência Flash de Lançamento Beta",
  "Flash x Binance": "Flash x Bingo",
  "Launch Flash Wallet extension & application Android + IOS":
    "Lançamento da Carteira Flash Extensão & aplicação Android + IOS",
  "AML compliance": "Conformidade AML",
  "Flash x Synaps": "Flash x Sinaps",
  "Flash x Certik": "Flash x Certik",
  "Launch Flash Transfer extension & application Android + IOS":
    "Lançamento da extensão Flash Transfer & aplicação Android + IOS",
  2023: "2023",
  2024: "2024",

  "Flash 3.0": "Flash",
  "Flash Token": "Token FTT",
  "Flash token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. Flash token is also linked to NFT Airdrops, where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees.":
    "O símbolo FTT é um símbolo de governação ligado a todo o ecossistema das Tecnologias Flash. 2% de todas as receitas (taxas de transacção) serão reinvestidos no token sob a forma de um Buyback mensal. O token FTT está também ligado ao NFT 'Airdrops', onde os detentores receberão directamente 1% do volume mensal do token. O token é de cadeia cruzada, o que significa que pode ser comprado em múltiplas redes com o mesmo endereço. Também pode ser transferida para outras carteiras sem pagamento de taxas de transferência.",
  "Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. ":
    "Flash é um símbolo de cadeia cruzada que visa tornar-se um meio de troca dentro do ecossistema das Tecnologias Flash, que inclui serviços como Flash-transfer.com, Flash Wallet, e muito mais, de modo a que os utilizadores regulares não sejam expostos a taxas de transacção exorbitantes.Um dos principais objectivos do Flash 3.0 é proporcionar aos titulares a capacidade de negociar a um custo mais baixo. Além disso, proporcionará vários benefícios com base no número de fichas detidas pelo utilizador.O Flash 3.0 pode ser adquirido em diferentes cadeias de bloqueio com o mesmo endereço. Também pode ser transferido de uma carteira para outra sem taxas de transferência.",
  "Contract address": "Endereço do contrato",
  "Token name": "Nome do Token",
  "Total Supply": "Fornecimento total",
  Symbol: "Símbolo",
  "Available on": "Disponível em",
  "Audit Flash": "Flash de Auditoria",
  Marketing: "Marketing",
  Liquidity: "Liquidez",
  Burn: "Queimadura",
  Rewards: "Recompensas",
  DAYS: "DAYS",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS",
  "Integration of dead wallet feature in Flash Wallet": "Integration of dead wallet feature in Flash Wallet",
  "Launch of the Launchpad": "Launch of the Launchpad",
  "Opening of our branch in France": "Opening of our branch in France",
  "New partner in money transfer": "New partner in money transfer",
  "Finalization of the Flash Transfer website": "Finalization of the Flash Transfer website",
  "Completion of the Flash Transfer application": "Completion of the Flash Transfer application",
  "Exchange listing": "Exchange listing",

  "Auditing Flash Transfer & Flash Wallet apps":
    "Auditoria de aplicativos Flash Transfer e Flash Wallet",
  "Flash x Orange Money": "Flash x Dinheiro Laranja",
  "Flash x MTN": "Flash x MTN",
  "Launch of Flash Payment": "Lançamento do Pagamento Flash",
  "Launch of Dead Wallet": "Lançamento da Dead Wallet",
  "Waiting for PSAN registration in France":
    "Aguardando o registro do PSAN na França",
  "Waiting for VASP registration in UAE":
    "Aguardando registro VASP nos Emirados Árabes Unidos",
};
