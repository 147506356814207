export const Arabic = {
  "Flash Technologies L.L.C-FZ": "Flash Technologies L.L.C-FZ",
  "Audited by": "مدققة من قبل",
  Flash: "Flash",
  Technologies: "Technologies",
  "Buy Flash": "شراء فلاش",
  "Flash Technologies creates a money transfer ecosystem.":
    "تقوم Flash Technologies بإنشاء نظام بيئي لتحويل الأموال.",
  "Flash Transfer is the world's first platform that allows you to buy crypto with cash, transfer, or sell cryptocurrencies for cash through our network of merchants, with reasonable fees. Flash Wallet, our non-custodial wallet, has a smart contract that you can use to transfer your crypto in the event of death.  Find the best fees with Flash Dex, our aggregator swap & bridge!":
    "فلاش ترانسفير هي أول منصة في العالم تتيح لك شراء العملات المشفرة نقدًا أو تحويلها أو بيعها نقدًا من خلال شبكة التجار لدينا، مع رسوم معقولة. فلاش والت، محفظتنا غير الحاضنة، تحتوي على عقد ذكي يمكنك استخدامه لنقل عملاتك المشفرة في حال الوفاة. ابحث عن أفضل الرسوم مع فلاش داكس، المجمع للتبديل والجسر الخاص بنا!",
  CONTACT: "الاتصال",
  "Contact Us": "اتصل بنا",
  "Whether you have a question about one of our services or want to get involved in our mission, we are ready to help.":
    "سواء كان لديك سؤال حول إحدى خدماتنا أو ترغب في المشاركة في مهمتنا ، فنحن على استعداد للمساعدة.",
  "Follow us:": "تابعنا:",
  "First Name": "الاسم الأول",
  "Last Name": "اسم العائلة",
  "Your Email Address": "عنوان بريدك الإلكتروني",
  "Tell us about your project": "أخبرنا عن مشروعك",
  "Send Message": "ارسل رسالة",
  "Type Here": "اكتب هنا  ",
  Our: "كاء",
  "CORE TEAM": "والشر",
  "has worked with": "الإدراج",
  "Core Team": "Core Team",
  "Aladdin CEO & Founder": "علاء الدين الرئيس التنفيذي والمؤسس",
  "CEO & Founder of": "الرئيس التنفيذي ومؤسس",
  Blocklabchain: "لابتشين وهالك",
  Hulk: "بلوك ",
  "Safi CCO & Co-Founder": "صافي CCO والمؤسس المشارك",
  "Les Artisans d’Orient": "حرفيو الشرق",
  "Junior COO": "مدير العمليات المبتدئ",
  "Communication English & German. Works in": "مؤسسة دبلوماسية ، منشئ تغذية",
  "Diplomatic Institution": "التواصل الإنجليزية والألمانية. يعمل في",
  "Binance feed creator": "Binance",
  "Mimirk CPRO": "ميمرك CMO و CDO",
  "Chief Public Relations Officer. Works in": "كخبير في التسويق والعلاقات العامة",
  "Public Relations": "الرئيس التنفيذي للتسويق. يعمل",
  "Dev. Team": "فريق التطوير",
  "Ernest CTO": "إرنست CTO",
  "Full stack & Blockchain Developer": "مكدس كامل ومطور Blockchain",
  "Jawad Developer": "جواد للتطوير العقاري",
  "Community Manager": "مدير المجتمع",
  "Frontend Developer": "مطور نهاية المقدمة",
  "Oleksii Developer": "الكسندر أوليكسي",
  "Full stack Developer": "مطور مكدس كامل",
  "Ahsan Developer": "ميخائيل للتطوير العقاري",
  "Umair Developer": "عمير للتطوير العقاري",
  "Usama Developer": "يلجيلم المطور العقاري",
  "Vadim Developer": "فاديم للتطوير العقاري",
  "Blockchain Developer": "مطور بلوكتشين",
  "Alex Developer": "اليكس للتطوير العقاري",
  "Frontend & Blockchain Developer": "الواجهة الأمامية ومطور Blockchain",
  "Hassam Editor video": "فيديو محرر حسام",
  "Video creation and editor": "إنشاء الفيديو ومحرره",
  "Runnart Designer": "رونارت ديزاينر",
  "Administrative graphic": "الرسم الإداري,",
  "White paper": "المستند التعريفي التمهيدي",
  "& App logo designer": "ومصمم شعار التطبيق ",
  "Eznau Illustrator": "إزناو الرسام",
  "Graphic flyers, logos": "النشرات الرسومية والشعارات ",
  "NFT for all products of": "NFT لجميع منتجات  ",
  "Flash Technologies": "Flash Technologies",
  "Numa Designer UI/UX": "نوما مصمم UI / UX",
  "Flash Transfer": "نقل فلاش",
  "Flash Wallet App": "محفظة التطبيق ",
  "UI/UX designer": "UI / مصمم UX",
  "Products": 'منتجات',
  "for": '',
  "ITO Designer": "ITO Designer",
  FAQ: "الأسئلة المتداولة",
  "What is Flash technologies?": "ما هي تقنيات الفلاش؟",
  "Flash Technologies is the name that represents all Flash projects.":
    "تقنيات الفلاش هو الاسم الذي يمثل جميع مشاريع فلاش.",
  "How can I buy Flash?": "كيف يمكنني شراء فلاش؟",
  "You can buy Flash token directly on":
    "يمكنك شراء الرموز الخاصة بنا مباشرة على ",
  "What is Flash Transfer?": "ما هو نقل الفلاش؟",
  "Flash Transfer is the main project of Flash Technologies. We will be able to bank people who do not have a bank account or who wish to free themselves from traditional banks. We can exchange cash for crypto via our various partners. Users of this service will be able to make transfers worldwide.":
    "نقل فلاش هو المشروع الرئيسي لتقنيات فلاش. سنكون قادرين على التعامل المصرفي مع الأشخاص الذين ليس لديهم حساب مصرفي أو الذين يرغبون في تحرير أنفسهم من البنوك التقليدية. يمكننا استبدال النقود بالعملات المشفرة عبر شركائنا المختلفين. سيتمكن مستخدمو هذه الخدمة من إجراء التحويلات في جميع أنحاء العالم.",
  "What are the advantages of buying Flash ?": "ما هي مزايا شراء فلاش؟",
  "Your Flash increases in value according to the evolution of the course. When Flash Technologies projects evolve, it has a positive impact on the price.":
    "تزداد قيمة الفلاش الخاص بك وفقا لتطور الدورة التدريبية. عندما تتطور مشاريع Flash Technologies ، يكون لها تأثير إيجابي على السعر.",
  "On which site will the token be listed?":
    "في أي موقع سيتم إدراج الرمز المميز؟",
  "The token will be listed on cex and dex.":
    "سيتم إدراج الرمز المميز على cex و dex.",
  "Why transaction fees?": "لماذا رسوم المعاملات؟",
  "Transaction fees support the project.": "رسوم المعاملات تدعم المشروع.",
  "Whitepaper EN": "المستند التقني EN",
  Presentation: "تقديم",
  "Using Flash Wallet": "استخدام محفظة فلاش",
  SERVICES: "خدمات",
  "(replace busd with ETH)": '',
  "Flash Ecosystem": "فلاش النظام البيئي",
  "Discover all our services.": "اكتشف جميع خدماتنا.",
  "Exchange your cash vs crypto or crypto vs cash. Buy directly cryptocurrency with our approved partners. You can also send your cryptocurrency and the receiver withdraws it in fiat all over the world using a Binance account or using your non custodial wallet ( Flash wallet, Metamask, Trust wallet ...).":
    "استبدل نقودك مقابل العملات المشفرة أو العملات المشفرة مقابل النقد. اشتر العملات المشفرة مباشرة مع شركائنا المعتمدين. يمكنك أيضا إرسال عملتك المشفرة ويقوم المستلم بسحبها بالعملات الورقية في جميع أنحاء العالم باستخدام حساب Binance أو باستخدام محفظتك غير الحافظة (محفظة فلاش ، Metamask ، محفظة Trust ...).",
  "Flash Dex": "فلاش ديكس",
  "Flash dex dapps is an all-in-one swap and bridge that allows users to swap their crypto assets from the same blockchain or from one blockchain to another. With the best possible direct market fees.":
    "Flash dex dapps عبارة عن مبادلة وجسر الكل في واحد يسمح للمستخدمين بمبادلة أصولهم المشفرة من نفس blockchain أو من blockchain إلى آخر. مع أفضل رسوم السوق المباشرة الممكنة.",
  "Flash Technologies has built a fully integrated cryptocurrency wallet that holders and merchants can use to buy, sell, stake, earn rewards and browse on internet. Flash Wallet has a special function that is unique in cryptospace. A very important feature that users will enjoy everywhere in the world.":
    "قامت Flash Technologies ببناء محفظة عملات رقمية متكاملة تماما يمكن لأصحابها والتجار استخدامها للشراء والبيع والتخزين وكسب المكافآت والتصفح على الإنترنت. محفظة فلاش لديها وظيفة خاصة فريدة من نوعها في cryptospace. ميزة مهمة للغاية سيستمتع بها المستخدمون في كل مكان في العالم.",
  "Flash Wallet": "محفظة فلاش",
  "Dead Wallet": "Flash Dead",
  "The 'dead-wallet' function. This function enables the recovery of cryptos in another wallet in case of loss of seed phrase and in the event of a death of a crypto holder. It is embedded with a smart-contract linked to a timer that automatically triggers the transfer of funds out of the wallet after a period of time has elapsed. With extra security if ever the dead wallet doesn't have enough gas costs.":
    "وظيفة 'المحفظة الميتة'. تتيح هذه الوظيفة استرداد العملات المشفرة في محفظة أخرى في حالة فقدان عبارة الاسترداد أو في حالة وفاة حامل العملة المشفرة. المحفظة مزودة بعقد ذكي مرتبط بمؤقت يؤدي إلى تحويل الأموال من المحفظة بعد فترة من الزمن؛ يجب على المستلم المطالبة بالعملات المشفرة. لاستخدام هذه الميزة، قم بزيارة تطبيق flash-wallet.com.",
  "Flash Pay": "Flash Pay",
  "FlashPay, escrow agreements can be created quickly by buyer or seller. Once receipt of goods or services is confirmed funds are immediately transferred to the seller via the smart contract. In the event of a dispute, Flash Pay settles the dispute with the evidence received. At no time are the funds held by Flash Pay, everything is decentralized via the smart contract. Flash Pay can only validate or refuse the payment, nothing else.":
    "FlashPay ، اتفاقيات الضمان يمكن إنشاؤها بسرعة من قبل المشتري أو البائع. بمجرد تأكيد استلام البضائع أو الخدمات ، يتم تحويل الأموال على الفور إلى البائع عبر العقد الذكي.     في حالة حدوث نزاع ، يقوم Flash Pay بتسوية النزاع بالأدلة المستلمة. في أي وقت من الأوقات ، يتم الاحتفاظ بالأموال بواسطة Flash Pay ، كل شيء يكون لامركزيًا عبر العقد الذكي. يمكن لـ Flash Pay فقط التحقق من الدفع أو رفضه ، ولا شيء غير ذلك.",
  "Customers and merchants have the opportunity to earn rewards by using the staking protocol. Each month stakers receive a % of our staking pool.":
    "يتمتع العملاء والتجار بفرصة كسب المكافآت باستخدام بروتوكول Staking . يحصل المخزنون كل شهر على ٪ من مجمع التخزين الخاص بنا.",
  "Flash Staking": "تخزين الفلاش",
  "Flash audit will audit smart contracts (tokens, bridges, staking, all types of dapps) and blockchains of our partners/customers on all networks.":
    "سيقوم تدقيق الفلاش بتدقيق العقود الذكية (الرموز ، والجسور ، والتخزين ، وجميع أنواع dapps) و blockchains لشركائنا / عملائنا على جميع الشبكات.    سيتعين على كل شريك / عميل يرغب في أن يكون في نظامنا البيئي الخضوع لتدقيق الأمان الخاص بنا للتأكد من عدم وجود تعليمات برمجية ضارة.",
  "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.":
    "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.",
  "Flash Audit": "تدقيق الفلاش",
  "Flash Launchpad": "فلاش لونش باد",
  "Flash pad is our launchpad for upcoming IDOs and allows crypto projects to create their tokens (Ethereum, BNB Chain, Polygon, Base, Avax, Cronos, Arbitrum, Blast) and raise funds in pre-sales or fair launches.You can also use the airdrop feature and the audit bot to quickly audit tokens.":
    "Flash pad هي منصة الإطلاق الخاصة بنا ل IDOs القادمة وتسمح لمشاريع التشفير بإنشاء الرموز المميزة الخاصة بها (BNB Chain و Polygon و Eth و Cronos) وجمع الأموال في مرحلة ما قبل البيع أو عمليات الإطلاق العادلة.",
  "Secured blockchain": "سلسلة الكتل الآمنة",
  "Single transaction": "معاملة واحدة",
  "Deflationary token": "الرمز الانكماشي",
  "Replace a Bank Account": "استبدال حساب بنكي",
  "WE ARE SUPPORTED": "نحن مدعومون",
  "Flash Recharge Card": "بطاقة شحن فلاش",
  "Flash Recharge": "إعادة شحن الفلاش",
  "The Flash recharge is a prepaid card that allows users to top up their account in $ BUSD on the Flash wallet without any intermediary.":
    "إعادة شحن الفلاش هي بطاقة مسبقة الدفع تتيح للمستخدمين تعبئة حساباتهم بالدولار BUSD على محفظة الفلاش دون أي وسيط.",
  "Flash Recharge allows users to top up their balance on Flash Wallet.":
    "تتيح إعادة شحن الفلاش للمستخدمين زيادة رصيدهم على محفظة فلاش",
  "The Flash recharge's cards will be printed at the time of purchase for an eco-responsible approach.":
    "ستتم طباعة بطاقات إعادة شحن الفلاش في وقت الشراء لاتباع نهج مسؤول بيئيا.",
  "Cash to crypto and vice versa.": "نقدا إلى التشفير والعكس صحيح.",
  "DOWNLOAD FROM": "تحميل من",
  "Our Services": "خدماتنا",
  "App": "App",
  Tokenomics: "الرموز المميزة",
  Partnership: "اشتراك",
  Roadmap: "خارطة الطريق",
  NFT: "NFT",
  Team: "فريق",
  Whitepaper: "مستند تقني",
  Audit: "مراجعه الحسابات",
  "Become a Partner": "كن شريكا",
  'Presale': "Presale",
  "Privacy Policy": "سياسة الخصوصية",
  "Legal Notice": "إشعار قانوني",
  License: "ترخيص",
  "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.":
    "تقنيات الفلاش: مركز الأعمال 1، الطابق M، فندق الميدان، ند الشبا، دبي، الإمارات العربية المتحدة",
  "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.":
    "فلاش تكنولوجيز: 66 شارع الشانزليزيه، 75008، باريس.",
  "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.":
    "تقنيات فلاش: أبيدجان - كوكودي، هضاب Les II، الشريحة السابعة، أبيدجان",
  Pages: "الصفحات",
  Documents: "الوثائق",
  Legal: "قانوني",
  "Join Our Newsletter": "اشترك في نشرتنا الإخبارية",
  "Your email address": "عنوان بريدك الإلكتروني",
  Subscribe: "اشترك",
  "Will send you weekly updates for your better finance management.":
    "سوف نرسل لك تحديثات أسبوعية لإدارة مالية أفضل.",
  Partners: "Partners",
  Contact: "Contact",
  Staking: "عمادا",
  "Invest in your Future": "استثمر في مستقبلك",
  "Clients can stake Flash or our approved partner's tokens for a certain period of time in order to earn rewards and bonuses":
    "يمكن للعملاء مشاركة Flash أو الرموز المميزة لشريكنا المعتمد لفترة زمنية معينة من أجل كسب المكافآت والمكافآت",
  Stake: "وتد",
  "Stake FLASH and receive rewards.": "شارك FLASH واحصل على مكافآت.",
  "Complete Staking Term": "مدة التخزين الكاملة",
  "Users can choose the staking terms and hold their stake for s period of time to earn higher yield bonus returns.":
    "يمكن للمستخدمين اختيار شروط التخزين والاحتفاظ بحصتهم لفترة زمنية لكسب عوائد مكافأة ذات عائد أعلى.",
  "Pay via Flash and partner tokens": "الدفع عبر الفلاش والرموز المميزة للشريك",
  "After every successful stake, users are rewarded with Flash token as a farming bonus and partners tokens.":
    "بعد كل حصة ناجحة ، تتم مكافأة المستخدمين برمز Flash كمكافأة زراعية ورموز الشركاء.",
  "Listing & Partners": "الإدراج والشركاء",
  "Seen in the media": "شوهد في وسائل الإعلام",
  "GET YOUR NFT UTILITY": "احصل على أداة NFT الخاصة بك",
  Airdrop: "انزال",
  "Each month receive Flash in your wallet.": "كل شهر تلقي فلاش في محفظتك.",
  "Holders of the AIRDROP NFTS receive Flash tokens every month. The amount differs depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed in the future.":
    "يتلقى حاملو AIRDROP NFTS رموز فلاش كل شهر. يختلف المبلغ حسب ندرة NFT. كما أنه يتيح الوصول إلى مزايا أخرى بما في ذلك نادي VIP (استدعاء nft والرموز) والقائمة البيضاء والخصومات والمزايا الأخرى التي سيتم الكشف عنها في المستقبل.",
  "Ranking Increase": "زيادة الترتيب",
  "Will quickly increase the privilege ranking.":
    "سيزيد بسرعة من ترتيب الامتياز.",
  "The NFTS RANKING INCREASE allow you to increase quickly your ranking points on Flash Transfer Website. The earned points depend on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "تسمح لك زيادة تصنيف NFTS بزيادة نقاط الترتيب الخاصة بك بسرعة على موقع Flash Transfer. تعتمد النقاط المكتسبة على ندرة NFT. كما يتيح الوصول إلى مزايا أخرى بما في ذلك نادي VIP (استدعاء NFT والرموز) والقائمة البيضاء والخصومات والمزايا الأخرى التي سيتم الكشف عنها لاحقا.",
  "Discount on transaction fees": "خصم على رسوم المعاملات",
  "Will entitle you to fees on money transfer site transactions.":
    "سوف يؤهلك للحصول على رسوم على معاملات موقع تحويل الأموال.",
  "The NFTS DISCOUNT ON TRANSACTION FEES provide discounts on transaction fees. The reduction is bigger depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "يوفر خصم NFTS على رسوم المعاملات خصومات على رسوم المعاملات. التخفيض أكبر اعتمادا على ندرة NFT. كما يتيح الوصول إلى مزايا أخرى بما في ذلك نادي VIP (استدعاء NFT والرموز) والقائمة البيضاء والخصومات والمزايا الأخرى التي سيتم الكشف عنها لاحقا.",
  "Random effect": "تأثير عشوائي",
  "Every 3 months or 6 months the function of the NFT changes.":
    "كل 3 أشهر أو 6 أشهر تتغير وظيفة NFT.",
  "The NFTS RANDOM EFFECT change abilities every 4 months. The benefit is greater depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "يغير التأثير العشوائي NFTS القدرات كل 4 أشهر. الفائدة أكبر اعتمادا على ندرة NFT. كما يتيح الوصول إلى مزايا أخرى بما في ذلك نادي VIP (استدعاء NFT والرموز) والقائمة البيضاء والخصومات والمزايا الأخرى التي سيتم الكشف عنها لاحقا.",
  "Flash Technologies Roadmap": "خارطة طريق تقنيات الفلاش",
  "August 2021": "أغسطس 2021",
  "Market study and meeting with professionals for the feasibility of the project.":
    "دراسة السوق والاجتماع مع المهنيين لجدوى المشروع.",
  "September 2021": "سبتمبر 2021",
  "Definition of the project and creation of the whitepaper.":
    "تعريف المشروع وإنشاء الورقة البيضاء.",
  "October 2021": "أكتوبر 2021",
  "Creation of the team of developers. Creation of the Flash Token Website.":
    "إنشاء فريق من المطورين. إنشاء موقع فلاش توكن.",
  "November 2021": "نوفمبر 2021",
  "Implementation of the Marketing strategy. Creation of the money transfer platform website. Meeting with influencers for the promotion of Flash Token. Launch of the Flash Token on the Bsc.":
    "تنفيذ استراتيجية التسويق. إنشاء موقع منصة تحويل الأموال. لقاء مع المؤثرين للترويج لرمز الفلاش. إطلاق رمز الفلاش على Bsc.",
  "December 2021": "ديسمبر 2021",
  "Promotion of the Flash Token by influencers.":
    "الترويج لرمز الفلاش من قبل المؤثرين.",
  "January 2022": "يناير 2022",
  "Launch of the 1st NFT collection. Launch of the Flash Token on the Bsc.":
    "إطلاق مجموعة 1st NFT. إطلاق رمز الفلاش على Bsc.",
  "February 2022": "فبراير 2022",
  "Release of the beta version of the money transfer. Coming out of the Nft mint platform Deployment.":
    "إصدار الإصدار التجريبي من تحويل الأموال. الخروج من نشر منصة النعناع Nft",
  2022: "2022",
  "Launch of Flash Technologies website": "إطلاق موقع تقنيات فلاش",
  "Unveiling of the first Flash Transfer partners":
    "الكشف عن أول شركاء فلاش ترانسبورت",
  "Launch of Flash Audit": "إطلاق فلاش لتدقيق الحسابات",
  "Vip Club Launch (only for the 200 Nft)":
    "إطلاق نادي كبار الشخصيات (فقط ل 200 Nft)",
  "Launch of the advertising campaign with influencers + new ambassadors":
    "إطلاق الحملة الإعلانية مع المؤثرين + السفراء الجدد",
  "Development of the Audit platform": "تطوير منصة التدقيق",
  "Development of the Staking platform": "تطوير منصة التخزين",
  "Beta Launch Flash Transfer": "بيتا إطلاق فلاش نقل",
  "Flash x Binance": "فلاش × بينانس",
  "Launch Flash Wallet extension & application Android + IOS":
    "إطلاق تمديد محفظة فلاش وتطبيق أندرويد + IOS",
  "AML compliance": "الامتثال لمكافحة غسل الأموال",
  "Flash x Synaps": "فلاش × المشابك",
  "Flash x Certik": "فلاش × سيرتيك",
  "Launch Flash Transfer extension & application Android + IOS":
    "إطلاق تمديد نقل فلاش وتطبيق الروبوت + دائرة الرقابة الداخلية",
  2023: "2023",
  2024: "2024",
  "Flash 3.0": " فلاش",
  "Flash Token": "فلاش توكن",
  "Flash token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. Flash token is also linked to NFT Airdrops, where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees.":
    "رمز الفلاش هو رمز حوكمة مرتبط بالنظام البيئي لتقنيات الفلاش بالكامل. سيتم إعادة استثمار 2٪ من جميع الإيرادات (رسوم المعاملات) في الرمز المميز في شكل إعادة شراء شهرية. يرتبط رمز الفلاش أيضا ب NFT Airdrops ، حيث سيحصل حاملوه على 1٪ من حجم الرمز المميز الشهري مباشرة. الرمز المميز هو عبر السلسلة ، مما يعني أنه يمكن شراؤه على شبكات متعددة بنفس العنوان. يمكن أيضا تحويلها إلى محافظ أخرى دون دفع رسوم التحويل.",
  "Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. ":
    "Flash هو رمز مميز عبر السلسلة يهدف إلى أن يصبح وسيلة للتبادل داخل نظام Flash Technologies البيئي ، والذي يتضمن خدمات مثل Flash-transfer.com و Flash Wallet والمزيد ، بحيث لا يتعرض المستخدمون العاديون لرسوم معاملات باهظة .أحد الأهداف الأساسية ل Flash 3.0 هو تزويد حامليها بالقدرة على التداول بتكلفة أقل. بالإضافة إلى ذلك ، سيوفر مزايا مختلفة بناء على عدد الرموز المميزة التي يحتفظ بها المستخدم.يمكن شراء Flash 3.0 على سلاسل كتل مختلفة بنفس العنوان. يمكن أيضا نقلها من محفظة إلى أخرى بدون رسوم تحويل.",
  "Contract address": "عنوان العقد",
  "Token name": "اسم الرمز المميز",
  "Total Supply": "إجمالي العرض",
  Symbol: "رمز",
  "Available on": "متاح على",
  "Audit Flash": "فلاش التدقيق",
  Marketing: "تسويق",
  Liquidity: "سيولة",
  Burn: "حرق",
  Rewards: "Rewards",
  DAYS: "DAYS",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS",
  "Integration of dead wallet feature in Flash Wallet": "Integration of dead wallet feature in Flash Wallet",
  "Launch of the Launchpad": "Launch of the Launchpad",
  "Opening of our branch in France": "Opening of our branch in France",
  "New partner in money transfer": "New partner in money transfer",
  "Finalization of the Flash Transfer website": "Finalization of the Flash Transfer website",
  "Completion of the Flash Transfer application": "Completion of the Flash Transfer application",
  "Exchange listing": "Exchange listing",

  "Auditing Flash Transfer & Flash Wallet apps":
    "تدقيق تطبيقات Flash Transfer & Flash Wallet",
  "Flash x Orange Money": "فلاش x Orange Money",
  "Flash x MTN": "فلاش x MTN",
  "Launch of Flash Payment": "إطلاق خدمة الدفع السريع",
  "Launch of Dead Wallet": "إطلاق Dead Wallet",
  "Waiting for PSAN registration in France": "في انتظار تسجيل PSAN في فرنسا",
  "Waiting for VASP registration in UAE": "في انتظار تسجيل VASP في الإمارات",
};
